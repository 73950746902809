<template>
  <div class="container">
    <div class="head">
      <img src="../../assets/images/yuwan/moveHead.jpg" />
    </div>
    <div class="content">
      <div class="titleLogin">全国总决赛参赛证领取</div>
      <el-form
        :model="formInline"
        ref="formInline"
        class="demo-form-inline"
        label-width="111px"
      >
        <el-row :gutter="20" style="margin-left: 0px; margin-right: 0px">
          <el-col :span="18" class="numberContent">
            <el-input
              type="number"
              v-model="formInline.iphone"
              @blur="validatePhoneNumber"
              placeholder="参赛手机号"
            >
              <!-- <template #append></template> -->
            </el-input>
            <el-button
              class="sendBtn"
              @click="sendCode"
              :disabled="isSending || countdown > 0"
            >
              {{
                isSending
                  ? "发送中..."
                  : countdown > 0
                  ? `获取验证码${countdown}`
                  : title
              }}
            </el-button>
          </el-col>
          <el-col :span="18">
            <el-input
              v-model="formInline.code"
              placeholder="输入验证码"
              type="text"
            />
          </el-col>
          <el-col :span="18">
            <el-button class="inquireBtn" type="primary" @click="enery"
              >确认</el-button
            >
          </el-col>
          <div class="descTitle">
            <p class="matter">注意事项：</p>
            <div class="partTime">
              <div class="leftT">
                1、  参赛证领取时间:&nbsp;2024年7月7日9:00(武汉赛点)
              </div>
              <div class="rightT">2024年7月18日9:00(北京赛点)</div>
            </div>

            <p>2、  参赛证领取后即可预约赛前练习(本科A组、本科B组、高职组)</p>
            <p>3、  赛前练习仅可预约一次，确认后无法修改</p>
            <p>
              4、  入校报到时请出示参赛证，<span class="keyDesc"
                >请在报到时间内前往报到处领取竞赛资料袋</span
              >。
            </p>
          </div>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
 
<script>
import { ElMessage } from "element-plus";
import { moveAppointment } from "api/apis.js";

export default {
  data() {
    return {
      formInline: {
        iphone: "",
        code: "",
      },
      phone: "",
      isSending: false,
      countdown: 0,
      phoneRegex: /^1[3-9]\d{9}$/,
      phoneError: false,
      title: "获取验证码",
    };
  },
  methods: {
    validatePhoneNumber() {
      this.phoneError = this.phoneRegex.test(this.formInline.iphone);
    },
    sendCode() {
      if (!this.phoneError) {
        ElMessage({
          message: "请输入正确的手机号",
          type: "error",
        });
        return;
      }
      if (this.countdown > 0 || this.isSending) {
        return;
      }
      this.isSending = true;
      this.title = "重新获取验证码";
      this.startCountdown();
      let dataobj = new FormData();
      dataobj.append("phone", this.formInline.iphone);
      moveAppointment.sendVerification(dataobj).then((res) => {
        if (res.code === 0) {
          this.countdown = 0;
          this.isSending = false;
          ElMessage({
            message: res.msg,
            type: "error",
          });
        } else {
          this.formInline.code = res.sms_verification_code;
        }
      });
      setTimeout(() => {
        this.isSending = false;
      }, 500);
    },
    startCountdown() {
      this.countdown = 60;
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    },
    enery() {
      let dataobj = new FormData();
      dataobj.append("phone", this.formInline.iphone);
      dataobj.append("code", this.formInline.code);
      if (this.formInline.iphone && this.formInline.code) {
        moveAppointment.contrastVerification(dataobj).then((res) => {
          if (res.code === 200) {
            this.getInfo();
          } else {
            ElMessage({
              message: res.msg,
              type: "error",
            });
          }
        });
      } else {
        ElMessage({
          message: "请输入正确的手机号和验证码",
          type: "warning",
        });
      }
    },

    getInfo() {
      // this.iphone = this.$route.query.iphone;
      // this.dataList = [];
      let dataobj = new FormData();
      // dataobj.append("phone", "19130094787");
      dataobj.append("phone", this.formInline.iphone);
      dataobj.append("type", "国赛");
      moveAppointment.info(dataobj).then((res) => {
        if (res.code === 200) {
          let list = res.data || [];
          if (list.length == 0) {
            ElMessage({
              message: "该账号暂无信息，请联系组委会",
              type: "error",
            });
            return;
          }
          let idList = list.join(",");
          localStorage.setItem("iphone", this.formInline.iphone);
          localStorage.setItem("idList", idList);
          this.$router.push({
            path: "/appointment",
            query: { iphone: this.formInline.iphone, idList },
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "./movePort.scss";
</style>